import React from 'react';

export default function AboutSection() {
  return (
    <section className="py-12 text-center md:text-left">
      <h2 className="mb-6 text-[20px] font-bold md:text-[40px]">
        About Crio.Do
      </h2>

      <p className="mb-6 text-[16px] text-v5-neutral-500 md:text-[24px]">
        We are an experiential learning platform that helps you gain hands-on,
        industry-relevant skills to land your dream job. With Crio.Do You—
        <span className="font-semibold text-green-600">
          {' '}
          Learn. Build. Get Hired!
        </span>
      </p>

      <p className="text-base font-semibold text-gray-800 md:text-lg">
        <span>Master the latest tech </span>
        <span className="text-green-600">→</span>
        <span> Build real projects </span>
        <span className="text-green-600">→</span>
        <span> Gain work-like experience </span>
        <span className="text-green-600">→</span>
        <span> Land your dream tech job.</span>
      </p>
    </section>
  );
}
