import React from 'react';
import { format, add, isValid } from 'date-fns';

export default function TestImportantNote({ activationTime }) {
  let formattedTime = 'TBD'; // fallback string to prevent SSR crash

  if (activationTime) {
    const baseDate = new Date(activationTime);
    if (isValid(baseDate)) {
      const expiryDate = add(baseDate, { hours: 2 });
      if (isValid(expiryDate)) {
        formattedTime = format(expiryDate, 'hh:mm aa');
      }
    }
  }

  return (
    <div className="relative mx-auto my-8 w-full max-w-5xl rounded-3xl border border-black bg-[#F4FBF9] p-6 shadow-xl">
      <h2 className="mb-4 text-center font-rubik text-[16px] font-extrabold text-v5-neutral-600 md:text-[32px]">
        Important:
      </h2>

      <ul className="list-disc space-y-3 pl-6 font-rubik text-[16px] text-v5-neutral-500 md:text-[24px]">
        <li>
          Your test link will expire 2 hours after the scheduled time. Please
          ensure you take the test before {formattedTime}.
        </li>
        <li>
          The test duration is 30 minutes, and you’ll receive a 15-minute
          reminder during the session.
        </li>
        <li>A laptop is mandatory for the test.</li>
      </ul>

      <p className="mt-6 font-rubik text-[16px] text-gray-700">
        <strong>Note:</strong> Once you complete the test, you’ll receive a
        <strong>
          {' '}
          detailed skill report highlighting your strengths and areas for
          improvement
        </strong>
        , helping you take the right steps to upskill and advance your tech
        career.
      </p>
    </div>
  );
}
