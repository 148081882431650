import FormSubmitButton from '@components/LeadGenerationForm/FormComponents/FormSubmitButton';
import React from 'react';

export default function MockInterviewCTA({ onClick }) {
  return (
    <section className="mx-auto max-w-6xl gap-6 rounded-2xl bg-v5-green-200 px-6 py-10 text-center text-white shadow-lg md:flex md:items-center md:justify-between md:px-12 md:text-left">
      <div className="mb-6 md:mb-0">
        <h2 className="font-manrope text-[24px] font-bold md:text-[40px]">
          Stop Guessing. Start Knowing.
        </h2>
        <p className="mt-2 font-rubik text-[16px] font-light md:text-[20px]">
          Take your Free AI-powered mock interview now and discover how close
          you are to your dream job
        </p>
      </div>

      <button onClick={onClick}>
        <FormSubmitButton text={'Take Free Mock Interview'} />
      </button>
    </section>
  );
}
