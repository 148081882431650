import { useCustomToast } from '@components/extra/useCustomToast';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { LEAD_GEN_BOTTOM_STRIP_CHATBOT_CALLBACK } from '@src/constants/LeadGenerationConstants/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import useSendActivities from '@src/hooks/useSendActivities';
import CrioButton from '@components/v5/CrioButton';

const NavRequestCallbackButton = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { sendActivityHandler } = useSendActivities();

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        Thanks! We will give you a call or email you between 9 AM to 9 PM
      </p>
    </div>,
  );

  function onSubmit(email, phone) {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'CALL_ME_POPUP',
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    sendActivityHandler(ELeadSquaredActivityCode.CALL_ME_BACK_SUBMITTED);

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);

    if (Cookies.get('isPhoneVerified') === 'true') triggerSuccessToast();
  }

  return (
    <LeadGenerationButton
      wrapperClassName={''}
      buttonComponent={
        <CrioButton
          variant="secondary"
          className={`h-[30px] !text-[10px]`}
          onClick={() => {}}
        >
          {'Request Call Back'}
        </CrioButton>
      }
      isOnePageForm
      formHeading="Request Callback"
      pageOneButtonText="Call me back"
      //   type={'HiddenButton'}
      buttonLocation={LEAD_GEN_BOTTOM_STRIP_CHATBOT_CALLBACK}
      onSubmitPageOne={onSubmit}
      clickEvent={() => {
        GTM.track(gtmEvents.CALLME_OPENED);
        sendActivityHandler(ELeadSquaredActivityCode.REQUEST_CALL_BACK_CLICKED);
      }}
    ></LeadGenerationButton>
  );
};

export default NavRequestCallbackButton;
