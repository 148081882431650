import React from 'react';

export default function WhyTakeThisSection() {
  const points = [
    'No-fluff evaluation in under 30 minutes on where you stand.',
    'Immediate actionable feedback to work on.',
    'Clear proof of your strengths and areas to improve.',
  ];

  return (
    <section className="flex w-full flex-col gap-4 bg-white py-10 md:flex-row-reverse ">
      <div className="mx-auto grid max-w-7xl grid-cols-1 items-center gap-10 text-center md:grid-cols-2 md:text-left">
        {/* Text Section */}
        <div>
          <h2 className="mb-6 font-manrope text-[20px] font-extrabold text-v5-neutral-600 md:text-[40px]">
            Why Take This?
          </h2>
          <p className="mb-4 font-manrope text-[16px] font-extrabold text-v5-neutral-500 md:text-[24px]">
            Because you don’t know what’s holding you back until someone shows
            you.
          </p>
          <p className="mb-6 font-rubik text-[16px] font-normal text-gray-700">
            Tech interviews today test more than your basics, they assess:
            <br />
            <span className="font-medium text-v5-green-300">
              Clarity, Structure, and Problem-Solving Abilities.
            </span>
          </p>

          <ul className="list-inside list-disc space-y-4 pl-0 text-left text-v5-neutral-500">
            {points.map((point, idx) => (
              <li
                key={idx}
                className="font-rubik text-[14px] font-normal md:text-[16px]"
              >
                {point}
              </li>
            ))}
          </ul>
        </div>

        {/* Image Section */}
        <div className="flex flex-col items-center gap-4">
          <img
            src="/images/Section3.webp"
            alt="Interview Coaching"
            className="w-full object-contain md:w-[470px]"
          />
        </div>
      </div>
    </section>
  );
}
