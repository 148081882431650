import React, { useEffect } from 'react';

import { Dialog } from '@mui/material';
import Times from '@src/images/icons/Times';
import useResizer from '@components/extra/useResizer';
import { StaticImage } from 'gatsby-plugin-image';
import CrioButton from '@components/v5/CrioButton';
import useLogin from '@src/hooks/useLogin';
import useSendActivities from '@src/hooks/useSendActivities';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants';
import toast from 'react-hot-toast';

const CallBackModal = ({ setOpen, open, email }) => {
  const isMobile = useResizer();

  // Custom hook to handle login functionality
  const handleLogin = useLogin();

  const { sendActivityHandler } = useSendActivities();

  const programAdvisorActivityHandler = async () => {
    toast.success(
      `Our Program Advisor will reach out to you shortly to schedule a 1:1 with you. While you wait, kindly take a moment to explore our platform.`,
    );
    sendActivityHandler(ELeadSquaredActivityCode.PROGRAM_ADVISOR_SESSION);
    setOpen(false);
  };

  const callbackActivityHandler = async () => {
    toast.success(
      `Our Program Advisor will reach out to you shortly. While you wait, kindly take a moment to explore our platform.`,
    );
    sendActivityHandler(ELeadSquaredActivityCode.REQUEST_CALL_BACK_CLICKED);
    setOpen(false);
  };

  const signInActivityHandler = async () => {
    sendActivityHandler(ELeadSquaredActivityCode.SIGN_IN_CLICKED);
  };

  const onButtonClick = () => {
    if (!isMobile) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // popupViewHandler(variant);
  }, []);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '100dvw',
          width: '950px',
          margin: 'auto 1rem',
          borderRadius: '21px',
          overflow: 'hidden',
          boxShadow: 'none',
        },
      }}
    >
      <div
        className="absolute top-5 right-5 z-[1] h-[12px] cursor-pointer md:top-10 md:right-10 md:h-[18px]"
        onClick={() => {
          setOpen(false);
        }}
      >
        <Times />
      </div>

      <div className="flex h-full flex-col bg-v5-neutral-200 md:flex-row">
        <div className="block bg-v5-green-200 md:hidden">
          <StaticImage
            loading="lazy"
            src={`/images/CallbackPopupImageMobile.webp`}
            className="h-[30px] w-[280px] rounded-l-[20px]"
            placeholder="blurred"
            alt="About Us"
            width={280}
          />
        </div>
        <div className="hidden bg-v5-green-200 md:block">
          <img
            loading="lazy"
            src={`/images/CallbackPopupImage.webp`}
            className="h-full w-[320px] rounded-t-[12px]"
            placeholder="blurred"
            alt="About Us"
            width={212}
          />
        </div>
        <div className="flex flex-col items-center gap-4 p-3 py-10 md:items-start md:gap-4 md:p-[60px] md:px-10">
          <h2 className="font-manrope text-[16px] font-extrabold md:text-[30px]">
            You've Already Completed Your{' '}
            <span className="text-black">Crio-SAT!</span>
          </h2>

          <p className="font-manrope text-[14px] font-normal md:text-[22px]">
            We noticed you've already{' '}
            <span className="font-semibold">taken the mock interview</span>.
            <br />
            To receive your detailed assessment report from our program advisor
          </p>

          <button
            className="rounded-md bg-yellow-400 py-2 px-4 font-semibold text-black shadow-sm transition-all hover:bg-yellow-500"
            onClick={() => {
              programAdvisorActivityHandler();
            }}
          >
            Request 1:1 Session →
          </button>

          <p className="font-manrope text-[14px] font-normal md:text-[18px]">
            If you’re looking to{' '}
            <span className="font-semibold">re-attempt the test</span> or need{' '}
            <span className="font-semibold">further assistance</span>, you can
            reach out to us at
            <span className="ml-1 font-medium">063665-28148</span> or simply{' '}
            <a
              className="font-semibold text-green-700 underline"
              onClick={() => {
                callbackActivityHandler();
              }}
            >
              Request a Callback.
            </a>
          </p>

          <p className="font-manrope text-[14px] font-medium md:text-[18px]">
            While you wait, why not take a moment to explore our platform?
          </p>

          <CrioButton
            variant="secondary"
            className={`h-[38px]`}
            onClick={() => {
              handleLogin();
              signInActivityHandler();
            }}
          >
            {'Sign In'}
          </CrioButton>

          <p className="font-manrope text-[14px] font-medium md:text-[18px]">
            Keep striving for growth!
          </p>
        </div>
      </div>
    </Dialog>
  );
};

export default CallBackModal;
