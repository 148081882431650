import React from 'react';
import CrioSATIntroSection from './CrioSATIntroSection';
import TestScheduleCountdown from './TestScheduleCountdown';
import TestImportantNote from './TestImportantNote';

export default function CrioSATMockInterview({ interviewData }) {
  return (
    <div className="mx-auto mt-[80px]">
      <CrioSATIntroSection requirementId={interviewData?.requirement_id} />
      <TestScheduleCountdown
        activationTime={interviewData?.activation_time}
        interviewLink={interviewData?.link}
      />
      <TestImportantNote activationTime={interviewData?.activation_time} />
    </div>
  );
}
