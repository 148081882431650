import { useState, useEffect, useCallback } from 'react';

const API_ENDPOINTS = {
  TOKEN:
    'https://ik7twrxep5idayp3nhossvtfwm0rqgum.lambda-url.ap-south-1.on.aws',
  AVAILABILITY:
    'https://jvvu73qtfwga2qyeo3bgpnyzgu0uphfi.lambda-url.ap-south-1.on.aws/availability',
  BOOK_SLOT:
    'https://jvvu73qtfwga2qyeo3bgpnyzgu0uphfi.lambda-url.ap-south-1.on.aws/book',
  INTERVIEWS:
    'https://interview.geektrust.com/partnerservice/api/v1/partners/interviews',
};

const ORIGIN_HEADER = 'https://www.crio.do/';

const useGeekTrust = () => {
  const [token, setToken] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [availabilityError, setAvailabilityError] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [bookingError, setBookingError] = useState(null);
  const [interviewData, setInterviewData] = useState(null);
  const [interviewError, setInterviewError] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const storedToken = localStorage.getItem('geektrustToken');
        const storedTimestamp = localStorage.getItem('geektrustTokenTimestamp');
        const now = Date.now();
        const ONE_DAY_MS = 24 * 60 * 60 * 1000;

        if (
          storedToken &&
          storedTimestamp &&
          now - parseInt(storedTimestamp, 10) <= ONE_DAY_MS
        ) {
          setToken(storedToken);
        } else {
          const response = await fetch(API_ENDPOINTS.TOKEN, {
            headers: { Origin: ORIGIN_HEADER },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch token');
          }
          const data = await response.json();
          const newToken = data.token;

          localStorage.setItem('geektrustToken', newToken);
          localStorage.setItem('geektrustTokenTimestamp', now.toString());
          setToken(newToken);
        }
      } catch (err) {
        console.error('Error fetching token:', err);
        setTokenError(err.message);
      }
    };

    fetchToken();
  }, []);

  const fetchSlotAvailability = useCallback(async () => {
    setAvailabilityError(null);
    try {
      const response = await fetch(API_ENDPOINTS.AVAILABILITY, {
        headers: { Origin: ORIGIN_HEADER },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch availability');
      }

      const data = await response.json();
      setAvailabilityData(data);
      return data; // ✅ return the response data
    } catch (err) {
      console.error('Failed to fetch availability:', err);
      setAvailabilityError(err.message);
      return null; // ✅ optional fallback
    }
  }, []);

  const bookSlotById = useCallback(
    async (slot) => {
      setBookingError(null);
      try {
        if (!token) {
          throw new Error('Token not available');
        }
        const response = await fetch(API_ENDPOINTS.BOOK_SLOT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            Origin: ORIGIN_HEADER,
          },
          body: JSON.stringify({ slot }),
        });
        if (!response.ok) {
          throw new Error('Failed to book slot');
        }
        const data = await response.json();
        setBookingData(data);
      } catch (err) {
        console.error('Failed to book slot:', err);
        setBookingError(err.message);
      }
    },
    [token],
  );

  const fetchInterviewData = useCallback(
    async (email) => {
      setInterviewError(null);
      try {
        if (!token) {
          throw new Error('Token not available');
        }
        const response = await fetch(
          `${API_ENDPOINTS.INTERVIEWS}?email=${encodeURIComponent(email)}`,
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${token}`,
              Origin: ORIGIN_HEADER,
            },
          },
        );
        if (!response.ok) {
          throw new Error('Failed to fetch interview data');
        }
        const data = await response.json();

        setInterviewData(data);
        return data;
      } catch (err) {
        console.error('Failed to fetch interview data:', err);
        setInterviewError(err.message);
      }
    },
    [token],
  );

  const registerInterview = useCallback(
    async (details) => {
      try {
        if (!token) {
          throw new Error('Token not available');
        }
        const response = await fetch(API_ENDPOINTS.INTERVIEWS, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Origin: ORIGIN_HEADER,
          },
          body: JSON.stringify(details),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to register interview. Status: ${response.status}`,
          );
        }

        const responseData = await response.json();
        return responseData;
      } catch (err) {
        console.error('Failed to register interview:', err);
      }
    },
    [token],
  );

  return {
    fetchSlotAvailability,
    bookSlotById,
    fetchInterviewData,
    registerInterview,
    availabilityData,
    availabilityError,
    bookingData,
    bookingError,
    interviewData,
    interviewError,
    token,
    tokenError,
  };
};

export default useGeekTrust;
