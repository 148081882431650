import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

SwiperCore.use([Pagination, Autoplay]);

const logos = [
  { name: 'Adobe', src: '/images/logos/Adobe_logo.webp' },
  { name: 'Amazon', src: '/images/logos/Amazon_logo.webp' },
  { name: 'Capgemini', src: '/images/logos/Capgemini_logo.webp' },
  { name: 'Cisco', src: '/images/logos/Cisco_logo.webp' },
  { name: 'Flipkart', src: '/images/logos/Flipkart_logo.webp' },
  { name: 'HP', src: '/images/logos/HP_logo.webp' },
  { name: 'Meesho', src: '/images/logos/Meesho_logo.webp' },
  { name: 'Paytm', src: '/images/logos/Paytm_logo.webp' },
  { name: 'Razorpay', src: '/images/logos/Razorpay_logo.webp' },
  { name: 'Siemens Energy', src: '/images/logos/Siemens_Energy_logo.webp' },
  { name: 'Sony', src: '/images/logos/Sony_logo.webp' },
  { name: 'SpiceJet', src: '/images/logos/SpiceJet_logo.webp' },
  { name: 'Swiggy', src: '/images/logos/Swiggy_logo.webp' },
  { name: 'Walmart', src: '/images/logos/Walmart_logo.webp' },
  { name: 'Zoho', src: '/images/logos/Zoho_logo.webp' },
];

const LogoCarousel = () => {
  return (
    <div className="flex w-full flex-col items-center">
      <Swiper
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        breakpoints={{
          0: { slidesPerView: 3, spaceBetween: 20 },
          640: { slidesPerView: 3, spaceBetween: 10 },
          1024: { slidesPerView: 6, spaceBetween: 24 },
        }}
        loop={true}
        className="w-full"
      >
        {logos.map((logo) => (
          <SwiperSlide key={logo.name} className="flex justify-center py-2">
            <div className="flex h-[60px] items-center justify-center rounded-2xl bg-[#f5fdfa] p-0 shadow md:h-[100px] md:w-[140px]">
              <img
                src={logo.src}
                alt={logo.name}
                className="h-full object-contain"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LogoCarousel;
