import { graphql } from 'gatsby';
import SEO from '@components/Common/SEO/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useContext, useEffect } from 'react';
import Navigation from '@components/Navigation/index';
import FooterV2 from '@components/FooterV2/index';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import ProjectsSection from '@components/v5/ProjectsSection/index';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import FAQSection from '@components/v5/FAQSection/index';
import WhyCrioSectionV2 from '@components/v5/WhyCrioSectionV2/index';
import WhyCrioSection from '@components/v5/WhyCrioSection/index';
import CrioButton from '@components/v5/CrioButton/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import { Link } from 'gatsby';
import { ERoutes } from '@src/constants/routes/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import SaleMiddleStrip from '@components/v5/Sale/SaleMiddleStrip';
import { stripData } from '@components/Sale/data';
import FspStylePageHero from '@components/v5/FspStylePageHero/index';
import StatsPanelFSP from '@components/v5/StatsPanelFSP/index';
import FspPricingCardSection from '@components/v5/FspPricingCardSection/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_HOME_PROJECTS,
  LEAD_GEN_FLOATING_CTA,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_WHY_CRIO,
  LEAD_GEN_PROJECTS,
  LEAD_GEN_CAREER_ASSISTANCE,
  LEAD_GEN_TESTIMONIALS,
  LEAD_GEN_FAQ,
  LEAD_GEN_CURRICULUM_PRICING_CARD,
} from '../constants/LeadGenerationConstants/index';
import ScrollButtonComponent from '@components/ScrollButtonComponent/index';
import { useWebEngage } from '@src/hooks/useWebEngage';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GTM } from '@src/analytics/gtm';
import TechnologiesMasterSection from '@components/v5/TechnologiesMasterSection/index';
import fsp from '../seo/jsonld/fsd.json';
import fdt from '../seo/jsonld/fdt.json';
import bdt from '../seo/jsonld/bdt.json';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import useCrioStat from '@src/hooks/useCrioStats';
import FspCurriculumSectionV3 from '@components/v5/FspCurriculumSection-V3/index';
import WhyCrioSectionV3 from '@components/v5/WhyCrioSectionV3/index';
import GlassHighlights from '@components/GlassMorphism/GlassHighlights/index';
import accelerateCurriculumJsonData from '@src/PageData/accelerateCurriculum';
import useSendActivities from '@src/hooks/useSendActivities';

export default function AcceleratePage(props) {
  const { captureUserClickEvent } = useWebEngage();
  const state = useContext(GlobalStateContext);
  const { sendActivityHandler } = useSendActivities();

  const onPlacementButtonClick = () => {
    captureUserClickEvent('Get our Placement Stats', {
      placementLocation: 'FSP_PAGE',
    });

    sendActivityHandler(
      ELeadSquaredActivityCode.SD_PAGE_GET_OUR_PLACEMENT_STATS_CLICKED,
    );
  };
  const { data: queryData } = props;

  const { acceleratePageYaml: data } = queryData;
  console.log('🚀 ~ AcceleratePage ~ data:', data);

  const program = props.data.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID === data.PrimaryProgramID,
  );

  const programId = program?.PrimaryProgramID;
  const jsonLd =
    programId === EProgramIDs.FULL_STACK
      ? fdt
      : programId === EProgramIDs.FELLOWSHIP
      ? fsp
      : bdt;

  const sale = props.data.strapiSale.SaleID;

  const { placementStats } = useCrioStat();

  const getProjectsSectionPreContent = (sale) => {
    switch (sale) {
      case 'true':
        return (
          <div className="mx-4 mb-10 flex justify-center">
            <SaleMiddleStrip data={stripData[program.PrimaryProgramID]}>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_HOME_PROJECTS}
              />
            </SaleMiddleStrip>
          </div>
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    //webengage events specific to FSD Page(experiment):
    GTM.track(gtmEvents.PAGE_VIEW_FSD, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });

    //GA EVENT FOR PAGE VIEW:
    GTM.track(gtmEvents.PAGE_VIEW, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });
  }, []);

  const projects = [
    {
      title: <>QMoney</>,
      subtitle: (
        <>
          QMoney is a visual stock portfolio analyzer. It helps portfolio
          managers make trade recommendations for their clients.
        </>
      ),
      tag: '',
      image: 'CardSix',
      skills: [],
    },
    {
      title: <>QContent</>,
      subtitle: (
        <>
          QContent is a Hackerrank-like contest application built by applying
          Core Java + design skills.
        </>
      ),
      tag: '',
      image: 'CardOne',
      skills: [],
    },
    {
      title: <>QEats</>,
      subtitle: (
        <>
          QEats is a popular food ordering app that allows users to browse and
          order their favorite dishes from nearby restaurants.
        </>
      ),
      tag: '',
      image: 'CardThree',
      skills: [],
    },
    {
      title: <>XMeme</>,
      subtitle: (
        <>
          XMeme is a simple backend web application where users can post and see
          memes.
        </>
      ),
      tag: '',
      image: 'CardTwo',
      skills: [],
    },
    {
      title: <>XBoard</>,
      subtitle: (
        <>
          XBoard is a News Feed website that will feature the latest news for
          select topics, from Flipboard.
        </>
      ),
      tag: '',
      image: 'CardSix',
      skills: [],
    },
    {
      title: <>QKart Frontend</>,
      subtitle: (
        <>
          QKart Frontend is an e-commerce application offering a variety of
          products for customers to choose from
        </>
      ),
      tag: '',
      image: 'CardOne',
      skills: [],
    },
    {
      title: <>QTrip Dynamic</>,
      subtitle: (
        <>
          QTrip Dynamic is a travel website aimed at travellers looking for a
          multitude of adventures in different cities
        </>
      ),
      tag: '',
      image: 'CardThree',
      skills: [],
    },
    {
      title: <>XFlix</>,
      subtitle: (
        <>
          XFlix Backend is a video sharing platform which hosts videos. It also
          features uploading new videos by using external video links and
          playing these videos.
        </>
      ),
      tag: '',
      image: 'CardTwo',
      skills: [],
    },
    {
      title: <>QTify</>,
      subtitle: (
        <>
          QTify is a song-browsing application built from scratch using ReactJS
          paired with Material UI and Swiper to deliver a seamless and aesthetic
          user interface, offering songs from different albums and genres for
          music lovers.
        </>
      ),
      tag: '',
      image: 'CardEight',
      skills: [],
    },
  ];

  return (
    <div>
      <SEO
        title={data.SEOTitle}
        description={data.SEODescription}
        canonicalUrl={data.canonicalUrl}
        meta={data.metatags || []}
      >
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </SEO>
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Apply & Start Free"
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={600}
      ></ScrollButtonComponent>
      <Navigation
        dark
        program={program.PrimaryProgramID}
        sale={sale}
      ></Navigation>
      <div className="bg-v5-green-700">
        <div className="text-v5-neutral-300">
          <FspStylePageHero
            dark
            program={program}
            title={data.heroTitle}
            image={data.heroImage.childImageSharp}
            description={data.heroDescription}
            subTitle={data.heroSubtitle}
            customBanner={
              <GlassHighlights
                className="px-0 md:gap-4"
                containerClassName=""
                highlights={[
                  {
                    title: 'Fully online',
                    svgType: 'fa-clipboard-list-check',
                    description: '<p>Program duration 6 to 9 months</p>',
                  },
                  {
                    title: 'Free',
                    svgType: 'fa-video',
                    description:
                      '<div><p>Trial Session</p><p class="hidden md:block">&nbsp;</p></div>',
                  },
                ]}
              />
            }
            stats={[]}
            sale={sale}
          ></FspStylePageHero>
        </div>
        {data.slug === '/fellowship-program-software-developer' ? (
          <WhyCrioSectionV2
            dark
            heading={data.highlightsTitle}
            description={data.highlightsDescription}
            quote={data.highlightsQuote}
            data={data.highlightsV2}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  text={
                    <div className="pr-2 text-left">
                      <h6 className="mb-1 font-manrope text-[18px] font-bold leading-[18px]">
                        Apply & Start for free
                      </h6>
                    </div>
                  }
                  iconClassName={`text-3xl`}
                  className={`h-[77px]`}
                  buttonLocation={LEAD_GEN_WHY_CRIO}
                />
              </PostContentCTALayout>
            }
          ></WhyCrioSectionV2>
        ) : data.slug === '/software-development-fellowship-program' ? (
          <WhyCrioSectionV3
            dark
            heading={data.highlightsTitle}
            description={data.highlightsDescription}
            quote={data.highlightsQuote}
            data={data.highlightsV2}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_WHY_CRIO}
                />
              </PostContentCTALayout>
            }
          ></WhyCrioSectionV3>
        ) : (
          <WhyCrioSection
            dark
            heading={data.highlightsTitle}
            description={data.highlightsDescription}
            quote={data.highlightsQuote}
            data={data.highlights}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  text={
                    <div className="pr-2 text-left">
                      <h6 className="mb-1 font-manrope text-[18px] font-bold leading-[18px]">
                        Apply & Start for free
                      </h6>
                    </div>
                  }
                  iconClassName={`text-3xl`}
                  className={`h-[77px]`}
                  buttonLocation={LEAD_GEN_WHY_CRIO}
                />
              </PostContentCTALayout>
            }
          ></WhyCrioSection>
        )}

        <CrackedCrioCareersSection
          dark
          postContent={
            <PostContentCTALayout>
              <div>
                <div className="-mb-10">
                  <StatsPanelFSP
                    stats={placementStats.map((stat) => {
                      return {
                        title: stat.content,
                        subtitle: `${stat.label}`,
                      };
                    })}
                    dark
                  ></StatsPanelFSP>
                </div>
                <PostContentCTALayout className="mb-10">
                  <Link to={ERoutes.PLACEMENTS}>
                    <CrioButton
                      variant="secondary"
                      onClick={onPlacementButtonClick}
                      className="h-[64px] w-[300px] border-white py-6 text-white sm:w-auto"
                    >
                      Get our Placement Stats
                    </CrioButton>
                  </Link>
                  <LeadGenerationButton
                    type="Apply & Start Free"
                    className="h-[64px] w-[300px]"
                    buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                  />
                </PostContentCTALayout>
              </div>
            </PostContentCTALayout>
          }
        ></CrackedCrioCareersSection>

        <RoundedLayout
          roundedBg="bg-v5-green-700"
          className="bg-white text-black"
          both
        >
          <div className="min-h-[800px] py-10">
            <ProjectsSection
              heading={data.projectsTitle}
              description={data.projectsDescriptionV2}
              projects={projects}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Apply & Start Free"
                    buttonLocation={LEAD_GEN_PROJECTS}
                  />
                </PostContentCTALayout>
              }
              preContent={getProjectsSectionPreContent(sale)}
              className="h-[400px] justify-start"
              isWithoutLabels
            ></ProjectsSection>
          </div>
        </RoundedLayout>

        <div className="mt-10">
          <FspCurriculumSectionV3
            dark
            heading={data.curriculumTitle}
            description={data.curriculumDescription}
            quote={data.curriculumQuote}
            type={data.PrimaryProgramID}
            curriculumV2={accelerateCurriculumJsonData}
            forceNoHeading
          ></FspCurriculumSectionV3>
        </div>

        {data.technologies ? (
          <TechnologiesMasterSection
            data={data.technologies}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={'LEAD_GEN_TECHNOLOGIES'}
                />
              </PostContentCTALayout>
            }
          />
        ) : (
          <></>
        )}

        <WhyCrioSection
          dark
          heading={data.careerTitle}
          description={data.careerDescription}
          data={data.careers}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_CAREER_ASSISTANCE}
              />
            </PostContentCTALayout>
          }
        ></WhyCrioSection>

        <FspPricingCardSection
          dark
          price={data.price}
          duration={data.priceDuration}
          sale={sale}
          program={program.PrimaryProgramID}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
              />
            </PostContentCTALayout>
          }
        ></FspPricingCardSection>
        <VideoTestimonialSection
          dark
          quote={data.testimonialQuote}
          disableCareerHandbook={
            data.PrimaryProgramID === EProgramIDs.QA_AUTOMATION_TESTING
          }
          postContent={
            <PostContentCTALayout>
              <div className="-mt-16 xxs:-mt-10 sm:-mt-0">
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_TESTIMONIALS}
                />
              </div>
            </PostContentCTALayout>
          }
        ></VideoTestimonialSection>
      </div>
      <RoundedLayout
        roundedBg="bg-v5-green-100"
        className="bg-white pb-16"
        bottom
      >
        <FAQSection
          data={data.faqs}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_FAQ}
              />
            </PostContentCTALayout>
          }
        ></FAQSection>
      </RoundedLayout>
      <FooterV2></FooterV2>
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    acceleratePageYaml(id: { eq: $id }) {
      PrimaryProgramID
      slug
      SEOTitle
      canonicalUrl
      SEODescription

      metatags {
        property
        content
      }

      heroTitle
      heroSubtitle
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      statsSubtitle

      highlightsTitle
      highlights {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      highlightsV2 {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      projectsTitle
      projectsDescription

      careerTitle
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote

      dreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      superDreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      curriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        anchor
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }

      advancedCurriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }

      steps

      price
      priceDuration

      faqs {
        question
        answer
      }

      jobGuarantee {
        question
        answer
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
