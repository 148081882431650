import React, { useState } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { EProgramIDs } from '@src/constants/data/programs/index';
import { ClientOnly } from '@components/extra/helpers';
import ReactHtmlParser from 'react-html-parser';
import useResizer from '@components/extra/useResizer';

const FAQSection = React.memo(function FAQSection({ data, ...props }) {
  const gradient = props.gradient;
  const isMobile = useResizer();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionChange = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getDataColumn = (dataColumn, baseIndex = 0) => (
    <div className="flex flex-1 flex-col gap-y-3">
      {dataColumn?.map((ele, idx) => {
        const absoluteIndex = baseIndex + idx;
        return (
          <CustomAccordion
            key={absoluteIndex}
            question={ele.question}
            answer={ele.answer}
            variant={props.variant}
            gradient={gradient}
            expanded={expandedIndex === absoluteIndex}
            onChange={() => handleAccordionChange(absoluteIndex)}
          />
        );
      })}
    </div>
  );

  const getAccordionPanels = () => {
    if (gradient && !isMobile) {
      const mid = Math.ceil(data?.length / 2);
      const dataHalfFirst = data?.slice(0, mid);
      const dataNextHalf = data?.slice(mid);

      return (
        <div className="flex gap-3">
          {getDataColumn(dataHalfFirst, 0)}
          {getDataColumn(dataNextHalf, mid)}
        </div>
      );
    } else {
      return getDataColumn(data);
    }
  };

  return (
    <SectionLayout
      className={`${gradient && 'bg-v5-green-700'}`}
      headingClassName={props.headingClassName}
      heading={
        <div className={`${gradient && 'text-v5-neutral-200'}`}>
          FAQs <span id="faqs"></span>
        </div>
      }
      {...props}
    >
      <div className="container">{getAccordionPanels()}</div>
    </SectionLayout>
  );
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '30px !important',
    overflow: 'hidden',
    border: 'none !important',
  },
  content: {
    margin: '8px 0px',
  },
}));

const CustomAccordion = ({
  question,
  answer,
  variant,
  gradient,
  expanded,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <ClientOnly>
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={onChange}
        classes={{ root: classes.root }}
        className={`z-[999] ${
          gradient && `bg-black bg-opacity-50 text-v5-neutral-200`
        }`}
      >
        <AccordionSummary
          className={`${
            gradient
              ? ''
              : variant === EProgramIDs.LAUNCH
              ? 'bg-launch-neutral-300'
              : 'bg-v5-neutral-200'
          } my-0 px-6 py-1 leading-relaxed`}
          classes={{ content: classes.content }}
          expandIcon={
            <FontAwesomeIcon
              icon={['far', 'plus']}
              className={`${gradient && 'text-white'} w-[32px] transition-all ${
                expanded ? 'rotate-45' : 'rotate-360'
              }`}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h6 className="font-rubik font-semibold">{question}</h6>
        </AccordionSummary>

        <AccordionDetails
          className={`${
            gradient
              ? ''
              : variant === EProgramIDs.LAUNCH
              ? 'bg-launch-neutral-300'
              : 'bg-v5-neutral-200'
          } px-6 pt-0 pb-4   leading-relaxed`}
        >
          <p>{ReactHtmlParser(answer)}</p>
        </AccordionDetails>
      </Accordion>
    </ClientOnly>
  );
};

export default FAQSection;
